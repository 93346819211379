import { CookiesProvider } from "react-cookie";
import "../sass/styles.scss";
import dynamic from "next/dynamic";
import { StrictMode } from "react";
import PageTransition from "../components/PageTransition";

const DynamicCookieBanner = dynamic(() => import("../components/Cookies"), {
  suspense: true,
  ssr: false,
});

function App({ Component, pageProps }) {
  return (
    <StrictMode>
      <PageTransition>        
      <CookiesProvider>
        <Component {...pageProps} />
        <DynamicCookieBanner />
      </CookiesProvider>
      </PageTransition>
    </StrictMode>
  );
}

export default App;
